import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Fade from 'react-reveal/Fade';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../Particle';
import BannerWrapper, {
  DiscountLabel,
  BannerObject,
  SmallImgDisplay,
  BackgroundShadow1,
  BackgroundShadow2,
} from './boosters.style';
import { Link } from 'gatsby-plugin-modal-routing';

import BannerObject2 from 'common/src/assets/image/saas/boosters_main.png';

const BannerSection = ({
  row,
  title,
  containedBtnStyle,
  handleOpenDemoModal,
  imageWrapper,
  contentWrapper,
}) => {
  return (
    <BannerWrapper id="banner_section">
      <BackgroundShadow1 />
      <BackgroundShadow2 />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading
              {...title}
              content="Boosters for the Ultimate Sales Flow"
            />
          </Box>
        </Box>
        <Box {...imageWrapper}>
          <Fade bottom style={{}}>
            <img
              src={BannerObject2}
              alt="banner image"
              style={{ margin: 'auto', width: '100%' }}
            />
          </Fade>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  dashboard: PropTypes.object,
  containedBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  dashboard: {
    width: '90%',
    float: 'right',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    boxShadow: '0px 10px 5px rgba(0,0,0,0.6)',
    MozBoxShadow: '0px 10px 5px rgba(0,0,0,0.6)',
    WebkitBoxShadow: '11px 5px 6px 4px rgba(0,0,0,0.6)',
    OBoxShadow: '0px 10px 5px rgba(0,0,0,0.6)',
  },
  imageWrapper: {
    width: '100%',
  },
  contentWrapper: {
    width: '100%',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    // mb: ['40px']
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px', '50px'],
    fontWeight: '700',
    color: '#ffffff',
    letterSpacing: '-0.025em',
    //mb: '20px',
    lineHeight: '1.5',
    textAlign: 'center',
    as: 'h1',
  },
  description: {
    fontSize: '21px',
    color: '#b1afaf',
    lineHeight: '1.7',
    mb: '0',
  },
  containedBtnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#52bd95',
    ml: ['10px', '18px'],
    type: 'button',
    colors: 'third',
    style: {
      borderColor: '#52bd95',
    },
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#2a313c',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default BannerSection;
